<!-- eslint-disable max-len -->
<template>
  <!-- 配送資訊 -->
  <div class="cart_block mb50">
    <h4 class="txt-bold">配送資訊</h4>
    <div class="delivery_area">
        <div class="delivery_item wifi">
          <!-- 產品資訊 -->
          <!-- <div class="product_name">
              <div class="pic">
                  <figure style="background-image: url(@/assets/img/shopping/product_2.png);"></figure>
              </div>
              <h6 class="txt-bold">
                  日本藍鑽石
                  <span class="txt-grey">與其他2項商品</span>
              </h6>
          </div> -->
          <!-- 填資料 -->
          <div class="item_in">
            <div class="fill_box">
              <p class="mg7">取件方式</p>
              <div class="fill_in">
                <template v-if="takeWay === '超取'">
                  <p class="col100">
                    <img class="delivery_icon" src="@/assets/img/shopping/FamilyMart.jpg" alt=""> 全家店到店
                  </p>
                  <p
                    v-if="cvsspot && cvsnum && family_name"
                    class="col100">
                      {{cvsspot}} {{family_name}}
                  </p>
                  <span
                    target="_blank"
                    class="txt-orange"
                    style="text-decoration: revert;"
                    @click="
                      listenFamilyMart();
                    ">
                      請選擇店鋪
                  </span>
                </template>
                <template
                  v-else-if="takeWay === '宅配'">
                    <p>
                      <img class="delivery_icon" src="@/assets/img/shopping/icon_car.svg" alt=""> 宅配
                    </p>
                </template>
                <p
                  v-else-if="takeWay === '機場'">
                    機場取件 ({{takeOptionText}})
                </p>
              </div>
            </div>

            <div class="fill_box">
              <p class="mg7">收件地址<span class="txt-redorange">*</span></p>
              <div class="fill_in">
                <div class="col100">
                  <input type="radio"
                    @input="$emit('selectType', '1')"
                    @click="getMemberInfo"
                    v-model="addressType"
                    name="product_loc"
                    id="same_with_member"
                    value="1"
                    :checked="takeWay === '宅配'">
                  <label for="same_with_member" class="p"><span></span> 同會員資訊</label>
                  <template v-if="takeWay === '宅配'">
                    <input type="radio"
                      @input="$emit('selectType', '2')"
                      v-model="addressType"
                      name="product_loc"
                      id="near"
                      value="2">
                    <label for="near" class="p"><span></span> 最近收件地址</label>
                    <input type="radio"
                      @input="$emit('selectType', '3')"
                      v-model="addressType"
                      name="product_loc"
                      id="new_loc"
                      value="3">
                    <label for="new_loc" class="p"><span></span> 新收件地址</label>
                  </template>
                </div>
                <!-- 最近收件地址, 點選打開可以選擇 -->
                <div
                  class="col100"
                  v-if="addressType === '2' && historyAddress.length > 0">
                    <div class="loc_box">
                      <template v-for="(data, i) in historyAddress">
                        <input
                          type="radio"
                          name="recent_loc"
                          :key="`address_input_${i}`"
                          :value="data"
                          :id="data.id"
                          @input="$emit('historyData', data)">
                        <label :key="`address_label_${i}`" :for="data.id" class="txt-grey">
                          <span></span> {{data.address}}
                        </label>
                        <br :key="`address_br_${i}`">
                      </template>
                    </div>
                </div>
                <div class="col50 loc_custom" v-if="takeWay === '宅配'">
                  <!-- 不開放編輯的時候, 加上readonly, 跟class="readonly" -->
                  <input type="text" v-model="zipcode" class="readonly" readonly>
                </div>
                <div class="col50 loc_custom">
                </div>
                <div class="col50 loc_custom">
                  <!-- 不開放編輯的時候, 加上readonly, 跟class="readonly" -->
                  <select
                    v-show="takeWay === '宅配'"
                    :disabled="addressType === '2'"
                    v-model="selectedCounty"
                    @input="$emit('county', $event)">
                      <option
                        v-for="(data, i ) in county"
                        :key="`county_${i}`"
                        :value="data">
                          {{data}}
                      </option>
                  </select>
                </div>
                <div class="col50 loc_custom"  v-if="takeWay === '宅配'">
                  <!-- 不開放編輯的時候, 加上readonly, 跟class="readonly" -->
                  <select
                    :disabled="addressType === '2'"
                    v-model="selectedDistrict"
                    @input="$emit('district', $event); $emit('zipcode', getZip($event));">
                      <option
                        v-for="(data, i ) in district"
                        :key="`district_${i}`"
                        :value="data">
                          {{data}}
                      </option>
                  </select>
                </div>
                <div class="col100"  v-if="takeWay === '宅配'">
                  <input type="text" v-model="address">
                </div>
              </div>
            </div>

            <div class="fill_box">
              <p class="mg14">收件人<span class="txt-redorange">*</span></p>
              <div class="fill_in">
                <div class="col50">
                  <input type="text" v-model="name">
                </div>
                <div class="col50">
                  <input type="radio"
                    v-model="sex"
                    id="m"
                    value="m">
                  <label for="m" class="p">
                    <span></span> 先生
                  </label>
                  <input type="radio"
                    id="f"
                    v-model="sex"
                    value="f">
                  <label for="f" class="p">
                    <span></span> 小姐
                  </label>
                </div>
              </div>
            </div>

            <div class="fill_box">
                <p class="mg14">Email<span class="txt-redorange">*</span></p>
                <div class="fill_in">
                  <div class="col50">
                      <input type="text" v-model="email">
                  </div>
                </div>
            </div>

            <div class="fill_box">
                <p class="mg14">手機<span class="txt-redorange">*</span></p>
                <div class="fill_in">
                  <div class="col50">
                    <select
                      v-model="country_code">
                        <option
                          v-for="(data, i) in countryPhoneCode"
                          :value="data.id"
                          :key="`countryPhoneCode_${i}`">
                            {{data.text}}
                        </option>
                    </select>
                  </div>
                  <div class="col50">
                    <input type="number" class="inputNumber" v-model="mobile" placeholder="0912345678">
                  </div>
                </div>
            </div>

            <div class="fill_box">
                <p class="mg14">市話</p>
                <div class="fill_in">
                    <div style="width: 80px;">
                        <input type="number" class="inputNumber"  v-model="tel">
                    </div>
                    <span style="margin: 0 15px">-</span>
                    <div style="flex: 1;">
                        <input type="number" class="inputNumber"  v-model="tel_1">
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import requestApi from '@/lib/http/index';
import { /* cloneDeep, */ isEqual } from '../../lib/lodash';

export default {
  name: 'CartShip',
  props: ['cartForm', 'historyAddress'],
  data() {
    return {
      intervalFamilyMart: null,
      addressType: 0,
      county: [],
      district: [],
      zipData: [],
      countryPhoneCode: [],
      test: 'm',
      takeOptionText: '',
      name: '',
      sex: '',
      email: '',
      country_code: '',
      mobile: '',
      tel: '',
      tel_1: '',

      cvsspot: '',
      cvsnum: '',
      family_name: '',

      zipcode: '',
      selectedCounty: '',
      selectedDistrict: '',
      address: '',
    };
  },
  computed: {
    ...mapState([
      'takeWay',
      'user',
    ]),
    fullData() {
      return {
        name: this.name,
        sex: this.sex,
        email: this.email,
        country_code: this.country_code,
        mobile: this.mobile,
        tel: this.tel,
        tel_1: this.tel_1,
        cvsspot: this.cvsspot,
        cvsnum: this.cvsnum,
        family_name: this.family_name,
        zipcode: this.zipcode,
        county: this.selectedCounty,
        district: this.selectedDistrict,
        address: this.address,
      };
    },
  },
  methods: {
    async getMemberInfo() {
      const res = await requestApi('member.getMemberProfile', { id: this.user.id });
      if (res.status) {
        const {
          name = '',
          sex = '',
          email = '',
          country_code: countryCode = '',
          mobile = '',
        } = res?.data ?? {};

        const data = {
          name,
          sex,
          email,
          country_code: countryCode,
          mobile,
        };
        this.methodUpdateCurrentData(data);
      } else {
        this.$customSWAL({
          icon: 'error',
          title: '錯誤',
          text: res.message,
          confirmButtonText: '確定',
        });
      }
    },
    getTakeOptionText() {
      const takeways = JSON.parse(localStorage.getItem('takeways'));
      const order = JSON.parse(localStorage.getItem('order'));
      for (let i = 0; i < takeways?.length ?? 0; i += 1) {
        if (takeways[i].id === order.take_option_id) {
          this.takeOptionText = takeways[i].text;
        }
      }
    },
    getZip(e) {
      const zip = this.zipData.filter((item) => item.district === e.target.value);
      if (zip.length === 1) {
        return zip[0].zipcode;
      }
      return '';
    },
    listenFamilyMart() {
      window.open(`http://mfme.map.com.tw/default.aspx?cvsname=wiho-web.howdesign.com.tw&cvsid=${this.user.id}&&exchange=true`);

      // 先還原, 刪掉localStorage裡殘存的data
      localStorage.removeItem('fm');
      clearInterval(this.intervalFamilyMart);

      // 開始監聽
      this.intervalFamilyMart = setInterval(() => {
        if (this.$route.name !== 'CartDetail') {
          clearInterval(this.intervalFamilyMart);
        }
        if (!localStorage.getItem('fm') && this.$route.name === 'CartDetail') {
          //
        } else {
          const familyMartData = JSON.parse(localStorage.getItem('fm'));
          const {
            cvsspot = '',
            cvsnum = '',
            name = '',
          } = familyMartData;

          const data = {
            cvsspot,
            cvsnum,
            family_name: name,
          };
          this.methodUpdateCurrentData(data);

          clearInterval(this.intervalFamilyMart);
        }
      }, 1000);
    },
    async getCounty() {
      // 取得縣市資料
      const { data: countyData } = await requestApi('req.getCounty');
      this.county = countyData.map((item) => item.county);
    },
    async getDistrict() {
      // 取得區域資料
      const county = this.selectedCounty || this.county[0];

      const { data: districtData } = await requestApi('req.getDistrict', { county });
      this.zipData = districtData;
      this.district = districtData?.map((item) => item.district);
    },
    getPhoneCode() {
      const getCountryPhoneCode = new Promise((resolve, reject) => {
        const result = requestApi('req.getCountryPhoneCode');
        result.then((res) => {
          if (res.status) {
            const arr = [];
            res.data.forEach((element) => {
              arr.push({
                text: element.phone_code_name,
                id: element.phone_code,
              });
            });
            resolve(arr);
          } else {
            reject(new Error('發生例外錯誤:無法取得國碼資料'));
          }
        });
      });
      getCountryPhoneCode.then((countryPhoneCode) => {
        this.countryPhoneCode = countryPhoneCode;
        this.country_code = '+886';
      });
    },
    // 預設選取同會員資訊
    methodInitSelectType() {
      this.$emit('selectType', '1');
      this.addressType = '1';
      this.getMemberInfo();
    },
    methodUpdateCurrentData({
      name = '',
      sex = '',
      email = '',
      country_code: countryCode = '',
      mobile = '',
      tel = '',
      tel_1: tel1 = '',

      cvsspot = '',
      cvsnum = '',
      family_name: familyName = '',

      zipcode = '',
      county = '',
      district = '',
      address = '',
    } = {}, force = false) {
      if (force || name) this.name = name;
      if (force || sex) this.sex = sex;
      if (force || email) this.email = email;
      if (force || countryCode) this.country_code = countryCode;
      if (force || mobile) this.mobile = mobile;
      if (force || tel) this.tel = tel;
      if (force || tel1) this.tel_1 = tel1;

      if (force || cvsspot) this.cvsspot = cvsspot;
      if (force || cvsnum) this.cvsnum = cvsnum;
      if (force || familyName) this.family_name = familyName;

      if (force || zipcode) this.zipcode = zipcode;
      if (force || county) this.selectedCounty = county;
      if (force || district) this.selectedDistrict = district;
      if (force || address) this.address = address;
    },
  },
  watch: {
    // 地址改變選擇，更新區域列表
    selectedCounty(newVal, oldVal) {
      if (!isEqual(newVal, oldVal) && newVal) {
        this.getDistrict();
      }
    },
    // props改變，更改本地
    cartForm: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal) && newVal) {
          this.methodUpdateCurrentData(newVal, true);
        }
      },
      deep: true,
    },
    // 向上更新本頁資料
    fullData(newVal, oldVal) {
      if (!isEqual(newVal, oldVal) && newVal) {
        this.$emit('update', newVal);
      }
    },
  },
  async created() {
    await this.getCounty();
    await this.getDistrict();
    this.methodInitSelectType();
  },
  mounted() {
    this.getPhoneCode();
    this.getTakeOptionText();
  },
};
</script>
<style lang="scss" scpoe>
  .inputRow {
    display: flex;
    align-items: center;
  }
  /* Chrome, Safari, Edge, Opera */
  input.inputNumber {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  /* Firefox */
  input.inputNumber[type=number] {
    -moz-appearance: textfield;
  }
</style>
